import { NgIf } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { pbButtonFill, pbButtonSize, pbButtonStyle, pbButtonType} from 'src/app/infrastructure/enums';
import { PbIconComponent } from '../pb-icon/pb-icon.component';


@Component({
    selector: 'pb-button',
    templateUrl: './pb-button.component.html',
    styleUrls: ['./pb-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, PbIconComponent]
})




export class PbButtonComponent implements OnInit {

  constructor() { }

  @Input() caption:string;
  @Input() actionTrackingLabel:string;
  @Input() buttonType:pbButtonType = pbButtonType.Button;
  
  @Input() buttonStyle: pbButtonStyle = pbButtonStyle.Primary;
  @Input() buttonFill: pbButtonFill = pbButtonFill.Filled;
  @Input() extraClasses:string;
  @Input() iconColour: string = "#333333";
  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();

  @Input() leftIcon:string;
  @Input() rightIcon:string;

  _isDisabled:boolean ;
  iconSize:string="16";

  _buttonSize:pbButtonSize = pbButtonSize.Regular;

  @Input() set buttonSize(value:pbButtonSize){
  
    this._buttonSize = value;
    if (value){
      switch(value){
        case pbButtonSize.Small:
          this.iconSize = "16";
          break;
        case pbButtonSize.Regular:
          this.iconSize = "24";
          break;
        case pbButtonSize.Large:
          this.iconSize = "32";
          break;
      }
    }
    
  }

  get buttonSize(){
    return this._buttonSize;
  }



  @Input() set isDisabled (value:boolean){
    this._isDisabled = value;
  }

  get isDisabled(){
    return this._isDisabled;
  }
 
  ngOnInit(): void {
  }

  OnClick(){
    this.clicked.emit(true);
  }

}
