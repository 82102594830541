
export enum pbButtonType {
    Button = "button",
    Submit = "submit",
}

export enum pbButtonSize {
    Small = "small",
    Regular = "regular",
    Large = "large"
}

export enum pbButtonStyle {
    Primary = "primary",
    Secondary = "secondary",
    Tertiary = "tertiary"
}

export enum pbButtonFill {
    Filled = "filled",
    Outlined = "outlined",
    Text = "text",
    Elevated = "elevated",
    Tonal = "tonal"
}